<template>
  <div class="sign_all bj">
    <div id="Sign" class="justify-content-center">
      <div style="padding-left: 3rem">
        <img height="65" :src="logo" alt="" />
      </div>
      <div class="color-w mt-5 sigin_form">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import product_info from "@/store/product_info.js";
var falg = {
  isStop: false,
};
export default {
  name: "Sign",
  components: {},
  data() {
    return {
      logo: product_info.logo,
    };
  },
  methods: {},
  beforeDestroy() {
    falg.isStop = true;
  },
  created() {
    this.$store.state.showNav = false;
  },
  computed: {
    ...mapGetters({
      counts: "count",
      startTexts: "startText",
      errorTips: "errorTip",
    }),
  },
};
</script>
<style>
.bj {
  background: url("../../../public/img/bj.png") no-repeat center center;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
}

img {
  vertical-align: center;
}

.login_item input {
  height: 1.3rem;
}

/*placeholder*/
input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bbb;
  font-size: 12px;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbb;
  font-size: 12px;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #bbb;
  font-size: 12px;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #bbb;
  font-size: 12px;
}

.form_item {
  border: 1px solid #ddd;
  border-radius: 40px;
  background-color: #fff;
}

.login_item {
  height: 2.3rem;
  line-height: 2.3rem;
  background-color: #fff;
  border-radius: 25px;
}

.login_icon {
  width: 80%;
}

.login_link_item a:hover {
  color: #47a1fb;
  text-decoration: none;
}

.send_code {
  height: 30px;
  right: 20px;
  letter-spacing: 3px;
  border: none;
  outline: none;
  border: 1px solid rgb(226, 223, 223);
  /* border-bottom: 1px solid #bbb; */
  background: transparent;
  line-height: 30px;
  margin-top: 4px;
}

.sigin_form {
  min-width: 300px;
  margin: 0 auto;
}

.sign_all {
  padding-top: 3rem;
}

.logo_img {
  height: 120px;
}

@media (max-width: 460px) {
  .logo_img {
    height: 70px;
  }
}
</style>
